<script setup lang="ts">
import type { Overlay, OverlayOverride } from '@/types/overlay';

defineOptions({
  name: 'MopOverlay',
});

const props = defineProps({
  overlay: {
    type: Object as PropType<Overlay>,
    required: true,
  },
});

const overlayElementRef: Ref<HTMLElement | null> = ref(null);
const dynamicElementRef: Ref<any | null> = ref(null);
const { closeAll, activeOverlayRef } = useMopOverlay();
const showClose = ref(false);
const isLoadingRef = ref(false);
const isOpenRef = ref(false);
const enableTransitionRef = ref(true);
let lockScrollAttemps = 0;
let timer: any;

const overlay = props.overlay;
const componentName = resolveComponent(overlay.componentName);
const overlayClasslist = [`overlay--${overlay.componentName.toLowerCase()}`, `overlay--${overlay.type.toLowerCase()}`];

overlay.overlayElementRef = overlayElementRef;

// to simplify logic only loading and showClose state can be emitted from loaded component and / or context override
// the rest need to be provided during open in the overrides
// context.emit('overlay-override', { loading?: boolean, showClose?: boolean });
const overrideStates = (override: OverlayOverride) => {
  if (!override) {
    return;
  }

  if (override.showClose !== undefined) {
    showClose.value = override.showClose;
  }

  if (override.loading !== undefined) {
    isLoadingRef.value = override.loading;
  }

  if (override.enableTransition !== undefined) {
    enableTransitionRef.value = override.enableTransition;
  }
};

onMounted(() => {
  watch(
    () => overlay,
    (overlay) => {
      nextTick(() => {
        setTimeout(() => {
          if (overlay.isOpen && activeOverlayRef.value?.componentKey === overlay.componentKey) {
            overrideStates(overlay.override);
            isOpenRef.value = true;
            if (overlay.override.lockScroll) {
              enableLockScroll();
            }
          } else {
            enableTransitionRef.value = true;
            isOpenRef.value = false;
            if (overlay.override.lockScroll) {
              releaseScroll();
            }
          }
        }, 10);
      });
    },
    { deep: true, immediate: true },
  );
});

function enableLockScroll() {
  clearTimeout(timer);
  // Make sure, element is in the dom
  timer = setTimeout(() => {
    if (!dynamicElementRef.value) {
      lockScrollAttemps++;
      if (lockScrollAttemps > 10) {
        return;
      }
      enableLockScroll();
      return;
    }

    if (!overlay.isOpen || activeOverlayRef.value?.componentKey !== overlay.componentKey) {
      return;
    }

    const lockElement: HTMLElement | null =
      typeof overlay.override.lockScroll !== 'string'
        ? overlayElementRef.value
        : dynamicElementRef.value.$el.querySelector(overlay.override.lockScroll);
    const preserveOverlayHistory: boolean =
      activeOverlayRef.value?.override?.persist !== true && !activeOverlayRef.value?.override?.persistRoutes?.length;
    const scrollElement = lockElement || overlayElementRef.value;
    if (scrollElement) {
      lockScroll(scrollElement, preserveOverlayHistory);
    }
  }, 100);
}

const componentProps = overlay.props || {};
const componentKey = overlay.componentKey;

function handleOverlayScrollTop(): void {
  if (overlayElementRef.value) {
    overlayElementRef.value.scrollTop = 0;
  }
}

function handleOverlayScrollBack(scrollBack: number): void {
  if (!scrollBack || typeof scrollBack !== 'number') {
    scrollBack = 0;
  }
  if (overlayElementRef.value) {
    overlayElementRef.value.scrollTop = scrollBack;
  }
}
</script>

<template>
  <div
    ref="overlayElementRef"
    :class="[
      'overlay',
      ...overlayClasslist,
      {
        'overlay--loading': isLoadingRef,
        'overlay--opened': isOpenRef,
        'overlay--transition': enableTransitionRef,
      },
    ]"
  >
    <div v-show="showClose" class="overlay__actions">
      <button class="overlay__action-close icon icon--x" @click="closeAll" />
    </div>
    <div class="overlay__body">
      <i v-show="isLoadingRef" class="icon icon--loading-asset" />
      <component
        :is="componentName"
        v-show="!isLoadingRef"
        ref="dynamicElementRef"
        :key="componentKey"
        v-bind="componentProps"
        @overlay-override="overrideStates"
        @overlay-scrolltop="handleOverlayScrollTop"
        @overlay-scrollback="handleOverlayScrollBack"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
$backgroundshadow: rgba(0, 0, 0, 0.5);
$transition-time: 0.15s; // has to match useOverlay timeout

.overlay__actions {
  @include z(global, content);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: $overlay-layer-action-height;
  width: 100%;
}

.overlay__action-close {
  border: 0;
  background: transparent;
  cursor: pointer;
  min-width: $space40;
  min-height: $space40;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  @include z(layers, above-shadow);
  @include webkit-overflow-scrolling;

  pointer-events: none;
  opacity: 0;
  position: fixed;
  transition: transform ease $transition-time;
  background-color: $white;
  overflow: auto;

  @include apply-print {
    display: none;
  }
}

.overlay--opened {
  opacity: 1;
  pointer-events: all;
}

.overlay__body {
  height: 100%;
}

.overlay--loading {
  .overlay__body {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-height: $space100;
  }
}

.overlay--fullscreen {
  @include z(layers, fullscreen-layer);

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(120%);

  &.overlay--opened {
    transform: translateY(0);
  }
}

.overlay--bottom {
  @include z(layers, above-layer);

  right: 0;
  bottom: 0;
  left: 0;
  max-height: 0;
  transform: translateY(120%);
  transition: all $transition-time ease;

  @include apply-upto(small) {
    max-height: calc(100vh - #{$sticky-small-header-height + 1});
    top: $sticky-small-header-height + 1;

    .wrapper--super-banner & {
      max-height: calc(100vh - #{$superbanner-height + $sticky-small-header-height + 1});
      top: $superbanner-height + $sticky-small-header-height + 1;
    }
  }

  &.overlay--opened {
    transform: translateY(0);
    max-height: 100vh;
  }
}

.overlay--left {
  top: 0;
  left: 0;
  width: 50%;
  transform: translateX(-100%);
  height: 100vh;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &.overlay--opened {
    transform: translateX(0);
  }
}

.overlay--right {
  top: 0;
  right: 0;
  width: 50%;
  transform: translateX(100%);
  height: 100vh;
  opacity: 1;
  overflow-y: auto;
  overflow-x: hidden;

  &.overlay--opened {
    transform: translateX(0);
  }
}

.overlay--top {
  top: 0;
  right: 0;
  left: 0;
  max-height: 0;
  transform: translateY(-120%);

  &.overlay--opened {
    max-height: 2000px;
    transform: translateY(0);
  }

  &.overlay--transition {
    transition: all $transition-time ease;
  }
}

.overlay--mopsuperbanneroverlay {
  @include z(layers, above-layer);

  top: $superbanner-height;

  @include apply-upto(small) {
    top: $superbanner-height-mobile;
  }
}

.overlay--centered {
  @include z(layers, fullscreen-layer);

  left: 50%;
  max-height: calc(100vh - #{$sticky-big-header-height + $space20});
  max-width: columns(24);
  overflow: auto;
  top: $sticky-big-header-height;
  transform: translateX(-50%);
  width: columns(24);

  @include apply-upto(infinite) {
    /**
     * Because of rule "Expected @include with a block to come before rule (order/order)" we need an "infinite"
     * breakpoint here to keep the order. Otherwise rules in here would have no effect.
     */

    .wrapper--super-banner & {
      max-height: calc(100vh - #{$superbanner-height + $sticky-big-header-height + $space20});
      top: $superbanner-height + $sticky-big-header-height;
    }
  }

  @include apply-upto(medium) {
    max-height: calc(100vh - #{$sticky-small-header-height + $space20});
    top: $sticky-small-header-height;

    .wrapper--super-banner & {
      max-height: calc(100vh - #{$superbanner-height + $sticky-small-header-height + $space20});
      top: $superbanner-height + $sticky-small-header-height;
    }
  }

  @include apply-upto(small) {
    max-width: 100%;
    width: 100%;
    bottom: 0;
    top: $sticky-small-header-height + 1;
    max-height: none;

    .wrapper--super-banner & {
      top: $superbanner-height + $sticky-small-header-height + 1;
      max-height: none;
    }
  }
}

.overlay--mopcountryselector {
  @include z(layers, cart);

  max-width: 640px;
  padding: $space25 $space40 $space10 $space20;

  @include apply-upto(small) {
    height: auto;
    bottom: auto;
    max-width: 95%;
    padding: $space25 $space20 $space10 $space20;
  }

  .overlay__actions {
    position: absolute;
    top: $space10;
    right: 0;
    padding: 0;
    width: 20px;
  }
}

.overlay--mopcategoryfilteroverlay {
  @include z(layers, cart);

  width: 320px;
  top: 0;
  top: var(--navh);
  max-height: 100vh;
  max-height: calc(var(--vh) - var(--navh));

  @include apply-upto(small) {
    width: 100%;
  }

  .overlay__actions {
    display: none;
  }

  .overlay__body {
    height: 100%;
  }
}

.overlay--mopheadermobilemenuoverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 50%;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  @include apply-upto(small) {
    width: 320px;
  }
}

// Top positioning controlled from MopHeader.vue
.overlay--mopheadersearchsuggestionsoverlay {
  transform: translateY(0%);
  background: $white;
  position: absolute;
  margin-top: $header-height;

  @include apply-upto(medium) {
    margin-top: $mobile-header-height;
  }
}

.overlay--mopheaderserviceoverlay,
.overlay--mopheaderaccountoverlay {
  @include z(layers, above-shadow);

  width: 330px;
  top: 0;
  padding-top: $sticky-big-header-height;
  height: 100vh;
  right: 0;
  left: auto;
  max-height: 100vh;
  transform: translateY(0);
  overflow-x: hidden;

  .overlay__actions {
    position: absolute;
    width: auto;
    right: -8px;
    margin-top: 12px;
  }

  :deep(.icon--x::after) {
    font-size: 40px;
  }
}

.overlay--mopheadernavigationoverlay {
  @include z(layers, above-shadow);

  top: 0;
  padding-top: $sticky-big-header-height;
  height: var(--vh);
  left: 0;
  right: auto;
  max-height: var(--vh);
  background-color: $white;
  min-width: 330px;
  transform: translateY(0);
  overflow: visible;

  .wrapper--super-banner & {
    padding-top: calc(#{$sticky-big-header-height} + var(--sb));
  }

  .overlay__actions {
    display: none;
  }
}

.overlay--mopstorefinderoverlay {
  overflow-x: hidden;

  &.overlay--opened {
    transform: unset; // safari causes expandable items to become white otherwise
  }

  .overlay__actions {
    padding: 0 $space5;
  }

  .overlay__body {
    @include apply-from(medium) {
      max-height: calc(100% - 50px);
      overflow: hidden;
    }
  }
}

.overlay--moppayinvoiceoverlay {
  max-width: 480px;
  width: calc(100% - #{$global-padding * 2});
  bottom: auto;

  .overlay__actions {
    position: absolute;
    top: $space10;
    right: 0;
    padding: 0;
    width: 20px;
  }
}

.overlay--mopaddwalletoverlay {
  max-width: 640px;
  bottom: auto;
}

.overlay--mopmemberbenefititemoverlay {
  max-width: 1024px;
  width: 100%;
  .overlay__actions {
    @include z(layers, above-layer);

    position: absolute;
    top: 0;
    right: 0;
  }
}

.overlay--mopcartsizeadviceoverlay {
  max-width: 670px;
  width: calc(100% - #{$global-padding * 2});
  bottom: auto;

  .overlay__actions {
    position: absolute;
    right: 0;
  }
}

.overlay--productimagegalleryoverlay {
  .overlay__actions {
    @include z(layers, above-layer);

    position: fixed;
    top: 0;
    right: columns(1);
  }
}

.overlay--centered.overlay--mopgiftcardpromptoverlay {
  max-width: 730px;
  bottom: unset;

  @include apply-upto(medium) {
    max-height: calc(100vh - #{$sticky-small-header-height + $space20});

    .wrapper--super-banner & {
      max-height: calc(100vh - #{$superbanner-height + $sticky-small-header-height + $space20});
    }
  }

  .overlay__actions {
    display: none;
  }
}

.overlay--centered.overlay--mopglassesvirtualtryoverlay {
  width: 100%;
  max-width: 80vw;
  height: 100%;
  max-height: 70vh;
  bottom: unset;
  overflow: hidden;

  @include apply-upto(small) {
    top: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
  }

  @include apply-from(small) {
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .overlay__actions {
    display: none;
  }
}

.overlay--mopaccountunsubscribeoverlay,
.overlay--mopaccountunsubscribesurveyoverlay {
  @include apply-from(medium) {
    max-width: 420px;
  }

  .overlay__actions {
    position: absolute;
    top: $space10;
    right: $space10;
    padding: 0;
    width: 20px;
  }
}
</style>
